import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HttpStatusCode,
} from '@angular/common/http';
import { Observable, catchError, of, switchMap } from 'rxjs';
import { AuthService } from 'src/app/features/auth/services/auth.service.abstract';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        private readonly _snackbar: MatSnackBar,
        private readonly _authService: AuthService
    ) {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((event: HttpErrorResponse) => {
                if (
                    event instanceof HttpErrorResponse &&
                    event.status === HttpStatusCode.Unauthorized
                ) {
                    const refresHeader = event.headers.get('X-Token');
                    if (
                        refresHeader === 'InvalidRefreshTokenException' ||
                        refresHeader === 'ExpiredRefreshTokenException'
                    ) {
                        // console.log("InvalidRefreshTokenException or ExpiredRefreshTokenException")
                        this._authService.logout();
                        return of(null);
                    }
                    if (refresHeader === 'ExpiredAccessTokenException') {
                        // console.log('Trying to renew access token...')
                        return this._authService.refreshAccessToken().pipe(
                            switchMap(() => {
                                return next.handle(request);
                            })
                        );
                    }
                }
                return next.handle(request);
            })
        );
    }
}
