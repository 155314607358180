/**
 * FastAPI
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The possible station types of a route.  A route containts at least the start- and destination stations.  Start = Start Intermediate = Intermediate Destination = Destination  Args:     IntEnum (_type_): _description_
 */
export type StationType = 'Start' | 'Intermediate' | 'Destination';

export const StationType = {
    Start: 'Start' as StationType,
    Intermediate: 'Intermediate' as StationType,
    Destination: 'Destination' as StationType
};

