import { Injectable } from '@angular/core';
import { FilterValue } from '../../model/filterValue';

/**
 * Localization service for shorter contents, such as buttons, input labels, page names.
 * For localizing longer contents, please use the PageLocalizationServcice.
 */
@Injectable({
    providedIn: 'root',
})
export class LocalizationService {
    /** General starts here. */
    public readonly appBrand = $localize`:Az alkalmazás branding neve.:Roadspace`;
    public readonly appMotto = $localize`:Az alkalmazás mottója.:Motorozás közösen.`;
    /** General ends here. */

    /** Page names start here. */
    public readonly pageLanding = $localize`:Az alkalmazás landing oldala.:Főoldal`;
    public readonly pageAuth = $localize`:A bejelentkezési oldal.:Bejelentkezés`;
    public readonly pageAbout = $localize`:A rólunk oldal elnevezése.:Rólunk`;
    public readonly pageFaq = $localize`:A gyakran ismételt kérdésekre vonatkozó oldal elnevezése.:Gyakran ismételt kérdések`;
    public readonly pagePrivacyPolicy = $localize`:Az adatkezelési tájékoztatóra vonatkozó oldal szövege.:Adatkezelési tájékoztató`;
    public readonly pageCookiePolicy = $localize`:Az süti tájékoztatóra vonatkozó oldal szövege.:Süti tájékoztató`;
    public readonly pageTermsAndConditions = $localize`:Az általános felhasználási feltételekre vonatkozó oldal szövege.:Általános felhasználási feltételek`;
    public readonly pageImpressum = $localize`:Az impresszumra vonatkozó oldal szövege.:Impresszum`;
    public readonly pageSignin = $localize`:A bejelentkezési oldal elnevezése:Bejelentkezés`;
    public readonly pageSignup = $localize`:A regisztrációs oldal elnevezése:Regisztráció`;
    public readonly pageRoutes = $localize`:Az útvonalak (túrák) oldal neve.:Motoros túrák`;
    public readonly pageProfile = $localize`:A profil oldal neve:Profil`;
    public readonly pageAddRoute = $localize`:Az útvonal létrehozó oldal elnevezése.:Útvonal hozzáadása`;
    public readonly pageViewRoute = $localize`:Az útvonal részleteit megjelenítő odlal elnevezése.:Útvonal részletek`;
    public readonly pageDashboard = $localize`:A belépést követő főoldal elnevezése:Irányítópult`;
    public readonly pageRating = $localize`:A felhasználói értékelések oldal elnevezése:Értékelések`;
    public readonly pageEvents = $localize`:Az események oldal elnevezése:Események`;
    public readonly pageAddEvent = $localize`:Az esemény létrehozó oldal elnevezése:Esemény létrehozása`;
    public readonly pageViewEvent = $localize`:Az esemény részleteit megjelenítő oldal elnevezése:Esemény részletek`;
    /** Page names end here. */

    /** UI element labels start here. */
    public readonly btnLabelSearch = $localize`:A keresés gomb label szövege.:Keresés`;
    public readonly btnLblSave = $localize`:A mentés gomb label szövege.:Mentés`;
    public readonly btnLabelLogout = $localize`:A kijelentkezés gomb label szövege:Kijelentkezés`;
    public readonly inputRequiredErrorMsg = $localize`:A kitöltendő üres mező esetére szóló hibaüzenet.: Kötelező mező`;
    public readonly inputInvalidFormatMsg = $localize`:A kitöltendő mező helytelen formátumára utaló hibaüzenet.:Helytelen formátum`;
    public readonly inputPasswordMismatchMsg = $localize`:A jelszó megerősítésére vonatkozó hibaüzenet.:A két jelszó nem egyezik.`;
    public readonly inputInvalidCountryMsg = $localize`:A nem elérhető országra vonatkozó hibaüzenet.:Helytelen ország név.`;
    public readonly inputLblFirstname = $localize`:A keresztnévre vonatkozó bemeneti mező label szövege.:Keresztnév`;
    public readonly inputLblLastname = $localize`:A vezetéknévre vonatkozó bemeneti mező label szövege.:Vezetéknév`;
    public readonly inputLblUsername = $localize`:A felhasználónévre vonatkozó bemeneti mező label szövege.:Felhasználónév`;
    public readonly inputLblEmail = $localize`:Az emailre vonatkozó bemeneti mező label szövege.:Email`;
    public readonly inputLblPassword = $localize`:A jelszóra vonatkozó bemeneti mező label szövege.:Jelszó`;
    public readonly inputLblDistanceSliderError = $localize`:Hibaüzenet a felhasználó koordinátáinak hiánya esetén.: A funkció használatához engedélyezd a helymeghatározást a böngészőben.`;
    public readonly inputLblDistanceSlider = $localize`:A felhasználó közelében lévő helyekre vonatkozó bemeneti mező.:Közelemben lévő útvonalak`;
    public readonly btnLabelCreateRoute = $localize`:Gomb új útvonal létrehozására.:Útvonal létrehozása`;
    public readonly btnLabelResetFilters = $localize`:Gomb a szűrési feltételek alaphelyzetbe állítására.:Alaphelyzet`;
    public readonly btnLabelNext = $localize`:A következő lépésre ugró gomb label szövege.:Következő`;
    public readonly btnLabelBack = $localize`:Az előző lépésre ugró gomb label szövege.:Vissza`;
    public readonly btnLabelReset = $localize`:Az alapértelmezett állapotra visszaállítása (pl. beviteli form esetén) gomb label szövege.:Alaphelyzet`;
    public readonly btnLblOpenProfile = $localize`:Gomb label, a felhasználói profil megnyitására.:Profil megnyitása`;
    public readonly btnLblSignin = $localize`:Gomb label, a bejelentkezésre.:Bejelentkezés`;
    public readonly btnLblSignup = $localize`:Gomb label, a regisztrációra.:Regisztráció`;
    public readonly btnLblTourRegistration = $localize`:Gomb, a túrára való regisztrálásra.:Jelentkezem a túrára`;
    public readonly btnLblTourUnregistration = $localize`:Gomb, a túráról való lejelentkezésre.:Lemondom a túrát`;
    public readonly btnLabelClose = $localize`:A bezárásra vonatkozó gomb szövege.:Bezárás`;
    public readonly btnLabelAddStop = $localize`:A megálló hozzáadására vonatkozó gomb szövege.:Megálló hozzáadása`;
    public readonly inputLblStartDate = $localize`:A túra kezdő dátumára vonatkozó bemeneti mező label szövege.:Kezdés időpontja`;
    public readonly inputLblEndDate = $localize`:A túra befejező dátumára vonatkozó bemeneti mező label szövege.:Várható befejezés időpontja`;
    public readonly inputLblYes = $localize`:Az igen értékre vonatkozó szöveg.:Igen`;
    public readonly inputLblNo = $localize`:A nem értékre vonatkozó szöveg.:Nem`;
    public readonly txtPersonalData = $localize`:A személyes adatokra vonatkozó szöveg.:Személyes adatok`;
    public readonly txtContactData = $localize`:A kapcsolati adatokra vonatkozó szöveg.:Kapcsolati adatok`;
    public readonly btnLblShowContactData = $localize`:Gomb a kapcsolati adatok megjelenítésére.:Kapcsolati adatok megjelenítése`;
    public readonly btnLblGeneralConfirm = $localize`:Gomb a megerősítésre.:Megértettem és elfogadom.`;
    public readonly inputLblSearchEventByName = $localize`:A túra nevére vonatkozó kereső mező label szövege.:Kezdd el gépelni a túra nevét...`;
    public readonly btnLblNextPage = $localize`:A következő oldalra ugró gomb label szövege.:Következő oldal`;
    public readonly btnLblPreviousPage = $localize`:Az előző oldalra ugró gomb label szövege.:Előző oldal`;
    public readonly btnLblFirstPage = $localize`:Az első oldalra ugró gomb label szövege.:Első oldal`;
    public readonly btnLblLastPage = $localize`:Az utolsó oldalra ugró gomb label szövege.:Utolsó oldal`;
    public readonly inputLblLocation = $localize`:A helyszínre vonatkozó bemeneti mező label szövege.:Helyszín`;
    public readonly inputLblWebsite = $localize`:A weboldalra vonatkozó bemeneti mező label szövege.:A rendezvény hivatalos weboldala`;
    /** UI element labels end here. */

    /** UI general texts start here. */
    public readonly txtLoading = $localize`:Az alkalmazás betöltésére vonatkozó szöveg.:Betöltés...`;
    public readonly txtAcceptPolicy = $localize`:A felhasználási feltételek elfogadására vonatkozó szöveg.:A bejelentkezéssel elfogadod a Roadspace felhasználási feltételeit és az adatkezeléssel kapcsolatos tájékoztatót. `;
    public readonly txtAwesome = $localize`:Értesítő szöveg:Szuper`;
    public readonly txtAdvancedSearch = $localize`:A részletes keresési feltételekre vonatkozó általános szöveg.:Részletes keresés`;
    public readonly txtName = $localize`:Általános, meghatározatlan névre vonatkozó szöveg.:Név`;
    public readonly txtGeneralData = $localize`:Általános, meghatározatlan dolgokra vonatkozó szöveg.:Általános adatok`;
    public readonly txtDetailedData = $localize`:Részletes, meghatározatlan dolgokra vonatkozó szöveg.:Részletes adatok`;
    public readonly txtGeneralDetailedDescription = $localize`:Általános, meghatározatlan dologra vonatkozó részletes leírásra vonatkozó szöveg.:Részletes leírás`;
    public readonly txtOrganizerData = $localize`:A szervezői adatokra vonatkozó szöveg.:Szervezői adatok`;
    public readonly txtFacebookAccVisibility = $localize`:A Facebook fiók láthatóságára vonatkozó szöveg.:Facebook fiók láthatósága`;
    public readonly txtPhoneVisibility = $localize`:A telefonszám láthatóságára vonatkozó szöveg.:Mobilszám láthatósága`;
    public readonly txtEmailVisibility = $localize`:Az email láthatóságára vonatkozó szöveg.:Email láthatósága`;
    public readonly txtUseSocialForAuth = $localize`:A közösségi fiókkal történő belépésre, regisztrálásra utaló szöveg.:Vagy használd közösségi fiókodat`;
    public readonly txtFollowUsFacebook = $localize`:A Facebook oldal követésére felhívó szöveg, amely például megjelenik a footerben.:Kövess minket a Facebookon is!`;
    public readonly txtFooterMenuLegal = $localize`:A footerben található, a jogi relevanciával bíró oldalakat összefoglaló menu headerje.:Jogi dokumentumok`;
    public readonly txtFooterMenuInfos = $localize`:A footerben található, az általános információkkal kapcsolatos oldalakat összefoglaló menü headerje.:Általános`;
    public readonly txtFrequentQuestions = $localize`:A gyakran ismételt kérdésekre vonatkozó szöveg.:Gyakran ismételt kérdések`;
    public readonly txtWhatsRoadspace = $localize`:Az oldal lényegét leíró szöveg kérdő formában.:Mi ez?`;
    public readonly txtRouteCharacteristics = $localize`:A túra tulajdonságaira vonatkozó header elnevezése.: Túra tulajdonságai`;
    public readonly txtMissingRouteCharacteristics = $localize`:Üzenet, amely szerint a szerverző nem állított be az útvonal kapcsán tulajdonságokat.:A szervező nem határozott meg tulajdonságokat.`;
    public readonly txtMissingRouteDescription = $localize`:Üzenet, amely szerint a szerverző nem adott meg az útvonal kapcsán részletes leírást.:A szervező nem adott meg a túrához leírást.`;
    public readonly txtShowAllCharacteristics = $localize`:Üzenet, arra vonatkozóan, hogy több túra összes tulajdonságát jelenítse meg az oldal.:Összes tulajdonság`;
    public readonly txtDetailedTourDescription = $localize`:Üzenet, arra vonatkozóan, hogy a túra létrehozója adja meg a túra részletes leírását.:Itt részletes leírást adhatsz a motoros túrádról...`;
    public readonly txtNoMoreResultsText = $localize`:Üzenet, amely szerint a felhasználó az útvonal találati lista végére ért.:Úgy tűnik, hogy a találati lista végére értél.`;
    public readonly txtNoResultsFound = $localize`:Üzenet, amely szerint a felhasználó keresési feltételei alapján nem talált találatokat.:Sajnos nincs találat.`;
    public readonly txtNoMoreResultsSubText = $localize`:Üzenet, amely szerint a felhasználó az útvonal találati lista végére ért, új találatokhoz módosítsa a szűrőket..:Próbálkoztál már a keresési feltételek módosításával?`;
    public readonly txtVerificationSuccess = $localize`:Üzenet, amely szerint az email megerősítés sikeres volt.:A fiókod készen áll a használatra, kattints a lenti gombra a belépéshez.`;
    public readonly txtCallToVerify = $localize`:Üzenet, amely felhívja a felhasználót a fiók megerősítésére.:A regisztráció befejezéséhez kattints az email címedre küldött megerősítő linkre.`;
    public readonly txtRouteRegistrationInfo = $localize`:Üzenet, amely felhívja a figyelmet arra, hogy megállóval rendelkező túrák esetén lehetőség van később becsatlakozni.:A megállóval rendelkező túra esetén megjelölheted, hogy nem a teljes túrán veszel részt.`;
    public readonly txtRouteRegistrationParticipationText = $localize`:Üzenet, a checkbox mellett, amivel a felhasználó bejelölheti, ha nem a teljes túrán vesz részt.:A túra egy részén veszek részt.`;
    public readonly txtRouteRegistrationHeader = $localize`:Üzenet, amely az útvonal regisztráció model headerben jelenik meg.:Jelentkezés`;
    public readonly txtAboutRoute = $localize`:Szöveg, amely az útvonal részleteinek megtekintésére utal.:Leírás`;
    public readonly txtTourData = $localize`:Szöveg, amely az útvonal adataira utal.:Túra adatai`;
    public readonly txtEventData = $localize`:Szöveg, amely az esemény adataira utal.:Esemény adatai`;
    public readonly txtOrganizer = $localize`:Szöveg, amely a túra szervezőjére utal.:Szervező`;
    public readonly txtMobileNumber = $localize`:Szöveg, amely a szervező telefonszámára utal.:Mobilszám`;
    public readonly txtTourEmptySpaces = $localize`:Szöveg, amely a túra szabad helyeire utal.:Szabad helyek`;
    public readonly txtPlannedRoute = $localize`:Szöveg, amely a tervezett útvonalra utal.:Tervezett útvonal`;
    public readonly txtMap = $localize`:Szöveg, amely a térképre utal.:Térkép`;
    public readonly txtMapExplanation = $localize`:Szöveg, amely a térkép használatára utal.:Az útvonalat az indulási, érkezési és az esetleges megállók
    figyelembevételével generáltuk, a túra tényleges útvonala ettől
    eltérhet. Kérünk, hogy mindig alaposan olvasd át a túra leírását
    is.`;
    public readonly txtAddStationsForMapDispaly = $localize`:Szöveg, amely a térképen való megjelenítéshez szükséges megállók hozzáadására utal.:A térképes nézet megtekintéséhez add meg az indulási és érkezési helyet!`;
    public readonly txtDisplayOnMap = $localize`:Szöveg, amely a térképen való megjelenítésre utal.:Megjelenítés a térképen`;
    public readonly txtStopPlural = $localize`:A megállók számára vonatkozó szöveg (pl. 5 további megálló).:megálló`;
    public readonly txtRouteEventUpcoming = $localize`:A túra eseményre vonatkozó szöveg, amely szerint az esemény még nem kezdődött el.:Közelgő esemény`;
    public readonly txtRouteEventOngoing = $localize`:A túra eseményre vonatkozó szöveg, amely szerint az esemény jelenleg zajlik.:Jelenleg zajló esemény`;
    public readonly txtRouteEventFinished = $localize`:A túra eseményre vonatkozó szöveg, amely szerint az esemény már véget ért.:Befejezett esemény`;
    public readonly txtUsersToRate = $localize`:A felhasználókra vonatkozó szöveg, akiket értékelni kell.:Értékelendő felhasználók`;
    public readonly txtRatingsReceived = $localize`:A kapott értékelésekre vonatkozó szöveg.:Kapott értékelések`;
    public readonly txtRatingsGiven = $localize`:Az adott értékelésekre vonatkozó szöveg.:Adott értékelések`;
    public readonly txtUserToReview = $localize`:Az értékelendő felhasználóra vonatkozó szöveg.:Felhasználó értékelése`;
    public readonly txtReviewDidParticipate = $localize`:Az értékelés részvételre vonatkozó szövege.:Részt vettél a túrán?`;
    public readonly txtHighwayInvolved = $localize`:Az autópályára vonatkozó szöveg.:Autópálya szakaszt érint a túra?`;
    public readonly txtHighwayInvolvedLabel = $localize`:Az autópályára vonatkozó leírás.:A túra (útdíjköteles) autópálya szakaszt érint.`;
    public readonly txtStatisticsRidersNoNow = $localize`:A jelenleg úton lévő motorosok számára vonatkozó szöveg:motoros van úton`;
    public readonly txtStatisticsRoutesNoNow = $localize`:A jelenleg elérhető túrák számára vonatkozó szöveg:motoros túra`;
    public readonly txtStatisticsEventsNoNow = $localize`:A jelenleg elérhető események számára vonatkozó szöveg:motoros esemény`;
    public readonly txtStatisticsKmDrivenNow = $localize`:A jelenleg megtett kilométerek számára vonatkozó szöveg:km közös gurulás`;
    public readonly txtCancelledTour = $localize`:A lemondott túrára vonatkozó szöveg.:Lemondott túra`;
    public readonly txtInvalidPageNumber = $localize`:Az érvénytelen oldalszámra vonatkozó szöveg.:Az oldalszám nem érvényes.`;
    public readonly txtEventWebsite = $localize`:Az esemény weboldalára vonatkozó szöveg.:Az esemény hivatalos oldala`;
    public readonly txtToursForEvent = $localize`:Az eseményhez tartozó túrákra vonatkozó szöveg.:Az eseményre tartó túrák`;
    public readonly txtNoRoutesForEvent = $localize`:Az eseményhez nem tartozik túra.:Az eseményhez még senki nem készített túrát`;
    public readonly txtOverview = $localize`:Az áttekintő szöveg.:Áttekintés`;

    public readonly reviewAspectSafety = $localize`:Az értékelés biztonsági szempontjára vonatkozó szöveg.:Biztonság`;
    public readonly reviewAspectAttitude = $localize`:Az értékelés hozzáállás szempontjára vonatkozó szöveg.:Hozzáállás`;
    public readonly reviewAspectContribution = $localize`:Az értékelés hozzájárulás szempontjára vonatkozó szöveg.:Hozzájárulás`;
    public readonly reviewAspectTechnicalConditions = $localize`:Az értékelés technikai feltételek szempontjára vonatkozó szöveg.:Technikai feltételek`;
    public readonly reviewAspectReliability = $localize`:Az értékelés megbízhatóság szempontjára vonatkozó szöveg.:Megbízhatóság`;
    public readonly reviewAspectFree = $localize`:Az értékelés szabadság szempontjára vonatkozó szöveg.:Szöveges értékelés`;

    public readonly reviewAspectSafetyDescription = $localize`:Az értékelés biztonsági szempontjára vonatkozó leírás.:Mennyire tartotta be a résztvevő a közlekedési szabályokat és a biztonságos vezetés alapelveit.`;
    public readonly reviewAspectAttitudeDescription = $localize`:Az értékelés hozzáállás szempontjára vonatkozó leírás.:Mennyire volt kedves és barátságos a résztvevő a többi motorossal szemben.`;
    public readonly reviewAspectContributionDescription = $localize`:Az értékelés hozzájárulás szempontjára vonatkozó leírás.:Mennyire volt proaktív a résztvevő a csoport segítésében, például útvonaltervezés vagy szükség esetén segítségnyújtás terén.`;
    public readonly reviewAspectTechnicalConditionsDescription = $localize`:Az értékelés technikai feltételek szempontjára vonatkozó leírás.:Mennyire volt felkészült a motorja és felszerelése a túrára (pl. megfelelő karbantartás, szükséges felszerelés megléte).`;
    public readonly reviewAspectReliabilityDescription = $localize`:Az értékelés megbízhatóság szempontjára vonatkozó leírás.:Mennyire volt megbízható a résztvevő, pl. időben érkezett-e a találkozókra és betartotta-e a megállapodásokat.`;
    public readonly reviewAspectFreeDescription = $localize`:Az értékelés kötetlen szempontjára vonatkozó leírás.:Foglald össze néhány mondatban, amit még fontosnak tartasz.`;

    public readonly reviewMissingForUser = $localize`:Az értékelés hiányára vonatkozó szöveg.:A felhasználó még nem kapott értékelést.`;
    public readonly emptyReviewList = $localize`:Az értékelések hiányára vonatkozó szöveg.:Még nincsenek értékelések.`;
    public readonly emptyToReviewList = $localize`:Az értékelendő felhasználók hiányára vonatkozó szöveg.:Nincsenek értékelendő felhasználók.`;
    public readonly txtConfirmationNeeded = $localize`:Az megerősítésre vonatkozó szöveg.:Megerősítés`;
    /** UI general texts end here. */

    /** UI route filters start here */
    public readonly filterStartPoint = $localize`:A túra kiindulópontjára vonatkozó szűrő.:Kiindulópont`;
    public readonly filterFinishPoint = $localize`:A túra befejező pontjára vonatkozó szűrő.:Befejezőpont`;
    public readonly filterCity = $localize`:A túra által érintett település, amely magában foglalja a kiinduló, megálló és befejező helyet.:Érintett település`;
    public readonly filterEventName = $localize`:Az esemény nevére vonatkozó szűrő.:Esemény neve`;
    public readonly filterIntermediateStation = $localize`:A túra köztes megállójára vonatkozó szűrő.:Megálló`;
    public readonly filterDateRange = $localize`:A túra kezdő- és befejező dátumára vonatkozó szűrő.:Dátum`;
    public readonly filterCountry = $localize`:A túra ország szerinti elhelyezkedésére vonatkozó szűrő.:Ország`;

    public readonly filterRidingStyle = $localize`:Motor típusokra vonatkozó szűrő.:Típus`;
    public readonly filterParticipantsNumber = $localize`:A résztvevők számára vonatkozó szűrő.:Résztvevők száma`;
    public readonly filterMaxParticipantsNo = $localize`:A maximális résztvevők számára vonatkozó szűrő.:Résztvevők maximális száma`;
    public readonly filterDistance = $localize`:A túra távolságára vonatkozó szűrő.:Távolság`;
    public readonly filterTerrain = $localize`:A túra útviszonyára vonatkozó szűrő.:Útviszonyok`;
    public readonly filterScenery = $localize`:A túra tájviszonyára vonatkozó szűrő.:Tájviszonyok`;
    public readonly filterPois = $localize`:A túra érdekes helyeire vonatkozó szűrő.:Látnivalók`;
    public readonly filterEvent = $localize`:A túra jellegére vonatkozó szűrő.:Esemény típus`;
    public readonly filterDuration = $localize`:A túra tartamára vonatkozó szűrő.:Időtartam`;
    public readonly filterDifficultyFactors = $localize`:A túra nehézségeire vonatkozó szűrő.:Nehézségek`;
    public readonly filterCommunityRecommendation = $localize`:A túra ismertségére vonatkozó szűrő.:Ismertség`;
    public readonly filterServices = $localize`:A túra során elérhető szolgáltatásokra vonatkozó szűrő.:Szolgáltatások`;

    public readonly allFilterValues = $localize`:Az összes típusra vonatkozó szűrő.:Összes`;

    public readonly sportRidingStyle = $localize`:A sport típusra vonatkozó szűrő.:Sport`;
    public readonly touringRidingStyle = $localize`:A túra típusra vonatkozó szűrő.:Túra`;
    public readonly chopperRidingStyle = $localize`:A chopper típusra vonatkozó szűrő.:Chopper`;
    public readonly cruiserRidingStyle = $localize`:A cruiser típusra vonatkozó szűrő.:Cruiser`;
    public readonly cafeRacerRidingStyle = $localize`:A café racer típusra vonatkozó szűrő.:Café racer`;
    public readonly enduroRidingStyle = $localize`:Az enduro típusra vonatkozó szűrő.:Enduro`;
    public readonly bobberRidingStyle = $localize`:A bobber típusra vonatkozó szűrő.:Bobber`;

    public readonly dualParticipantsNumber = $localize`:A páros résztvevőkre vonatkozó szűrő.:Páros`;
    public readonly smallParticipantsNumber = $localize`:A kis csoportos résztvevőkre vonatkozó szűrő.:Kisebb csoport`;
    public readonly midParticipantsNumber = $localize`:A közepes csoportos résztvevőkre vonatkozó szűrő.:Közepes csoport`;
    public readonly largeParticipantsNumber = $localize`:A nagyobb csoportos résztvevőkre vonatkozó szűrő.:Nagyobb csoport`;
    public readonly openParticipantsNumber = $localize`:A nyílt csoportos résztvevőkre vonatkozó szűrő.:Nyílt`;

    public readonly shortDistance = $localize`:A rövid távolságra vonatkozó szűrő.:Rövid`;
    public readonly midDistance = $localize`:A közepes távolságra vonatkozó szűrő.:Közepes`;
    public readonly longDistance = $localize`:A hosszú távolságra vonatkozó szűrő.:Hosszú`;

    public readonly onRoadTerrain = $localize`:A normál, aszfalt útviszonyra vonatkozó szűrő.:On-road (normál aszfalt)`;
    public readonly offRoadTerrain = $localize`:A terepes útviszonyra vonatkozó szűrő.:Off-road (terep)`;
    public readonly mixedTerrain = $localize`:A vegyes útviszonyra vonatkozó szűrő.:Vegyes (aszfalt-terep)`;

    public readonly mountainousScenery = $localize`:A hegyvidéki tájra vonatkozó szűrő.:Hegyvidéki`;
    public readonly forestedScenery = $localize`:Az erdős tájra vonatkozó szűrő.:Erdős`;
    public readonly urbanScenery = $localize`:A városi tájra vonatkozó szűrő.:Városi`;
    public readonly countrySideScenery = $localize`:Az országúti tájra vonatkozó szűrő.:Országúti`;

    public readonly historicPois = $localize`:A történelmi érdekes helyekre vonatkozó szűrő.:Történelmi`;
    public readonly scenicOverviewPois = $localize`:A panorámás kilátással rendelkező érdekes helyekre vonatkozó szűrő.:Panorámás kilátó`;
    public readonly cafesRestaurantsPois = $localize`:A vendéglátó hellyel rendelkező érdekes helyekre vonatkozó szűrő.:Vendéglátó helyek`;

    public readonly groupEvent = $localize`:A csoportos jellegre vonatkozó szűrő.:Csoportos`;
    public readonly soloEvent = $localize`:A solo jellegre vonatkozó szűrő.:Magán`;
    public readonly rallyEvent = $localize`:A verseny jellegre vonatkozó szűrő.:Verseny`;
    public readonly charityEvent = $localize`:A jótékonysági jellegre vonatkozó szűrő.:Jótékonysági`;
    public readonly festivalEvent = $localize`:A fesztivál jellegre vonatkozó szűrő.:Fesztivál`;

    public readonly halfDayDuration = $localize`:A félnapi jellegre vonatkozú szűrő.:Fél nap`;
    public readonly fullDayDuration = $localize`:A teljes napi jellegre vonatkozú szűrő.:Teljes nap`;
    public readonly multiDayDuration = $localize`:A több napi jellegre vonatkozú szűrő.:Több nap`;

    public readonly elevationDifficultyFactor = $localize`:Az emelkedőkre, mint nehézségre vonatkozó szűrő.:Emelkedők`;
    public readonly turnsDifficultyFactor = $localize`:A kanyarokra, mint nehézségre vonatkozó szűrő.:Kanyarok`;
    public readonly trafficDifficultyFactor = $localize`:A forgalomra, mint nehézségre vonatkozó szűrő.:Forgalom`;

    public readonly popularCommunityRecommendation = $localize`:Az ismertségre vonatkozó szűrő.:Közismert`;
    public readonly hiddenGemsCommunityRecommendation = $localize`:A titkos jellegre vonatkozó szűrő.:Rejtett kincs`;

    public readonly gasStationService = $localize`:A benzinkútra, mint elérhető szolgáltatásra vonatkozó szűrő.:Benzinkút`;
    public readonly restStopService = $localize`:A pihenőhelyre, mint elérhető szolgáltatásra vonatkozó szűrő.:Pihenőhely`;
    public readonly accommodationService = $localize`:A szálláshelyre, mint elérhető szolgáltatásra vonatkozó szűrő.:Szálláshely`;

    /** UI route filters end here. */

    /** UI messages start here. */
    public readonly msgEmailCannotBeUsed = $localize`:Hibaüzenet, amely szerint a felhasználó által megadott email cím nem használható (már létezik a felhasználónév vagy email).:Ez az email cím sajnos nem használható.`;
    public readonly msgSaveActionSuccess = $localize`:Értesítés, amely szerint a mentés művelet sikeres volt.:A mentés sikeres.`;
    public readonly msgMissingRoutes = $localize`:Az üres találati listát jelző szöveg az útvonalak esetében.:Úgy tűnik, hogy jelenleg nincs elérhető útvonal.`;
    public readonly msgMissingRoute = $localize`:Hibaüzenet, a nem létező útvonalak esetén.:A keresett útvonal nem érhető el.`;
    public readonly msgNoParticipants = $localize`:Értesítés, amely szerint az adott túrára még nem jelentkezett senki: Úgy tűnik, hogy a túrára még senki nem jelentkezett.`;
    public readonly msgOnlyForLoggedInUsers = $localize`:Hibaüzenet, amely szerint a funkció csak bejelentkezett felhasználóknak érhető el.:Ez a funkció csak bejelentkezett felhasználóknak érhető el!`;
    public readonly msgOnlyForParticipants = $localize`:Hibaüzenet, amely szerint a funkció csak a túrán résztvevő felhasználóknak érhető el.:Ez a funkció csak résztvevőknek érhető el!`;
    public readonly msgFunctionNotAvailable = $localize`:Értesítés, amely szerint a funkció jelenleg nem érhető el.:A funkció jelenleg nem érhető el.`;
    public readonly msgIncompleteProfile = $localize`:Értesítés, amely szerint a felhasználó még nem töltött ki minden adatot a profil oldalán.:Úgy tűnik, hogy a profilodat még nem töltötted ki teljesen.`;
    public readonly msgUnableToCreateRoute = $localize`:Értesítés, amely szerint a felhasználó valamilyen ok miatt nem képes útvonal létrehozására.:Enélkül sajnos nem tudsz útvonalakat létrehozni.`;
    public readonly msgInvalidCredentials = $localize`:Hibaüzenet, amely szerint a bejelentkezés során megadott email vagy jelszó nem megfelelő.:Sikertelen bejelentkezés, nem megfelelő email vagy jelszó.`;
    public readonly msgVerificationIsNeeded = $localize`:Hibaüzenet, amely szerint a bejelentkezés előtt az email cím megerősítése szükséges.:A bejelentkezéshez előbb erősítsd meg az email címedet.`;
    public readonly msgGeneralError = $localize`:Hibaüzenet, amely valamilyen általános hibára vonatkozik.:Sajnos váratlan hiba történt.`;
    public readonly msgInvalidStationOrder = $localize`:Hibaüzenet, amely akkor jelenik meg, ha a felhasználó a túra regisztráció során helytelen megálló sorrendet ad meg.:Az érkezési hely nem lehet előrébb az indulási helynél.`;
    public readonly msgStationsTheSame = $localize`:Hibaüzenet, amely akkor jelenik meg, ha a felhasználó a túra regisztráció során ugyanazt az érkezési és befejező megállót adja meg.:Az indulási és érkezési hely nem egyezhet meg..`;
    public readonly msgRouteRegistrationSuccess = $localize`:Értesítés, amely szerint a túra regisztráció sikeres volt.:Sikeresen jelentkeztél a túrára, jó gurulást!`;
    public readonly msgUserAlreadyRegistered = $localize`:Hibaüzenet, amely akkor jelenik meg, ha a felhasználó már regisztrált a túrára.:Már regisztráltál erre a túrára.`;
    public readonly msgOngoingEvent = $localize`:Értesítés, amely szerint a túra esemény jelenleg zajlik.:A túra jelenleg zajlik, a regisztráció nem lehetséges!`;
    public readonly msgFinishedEvent = $localize`:Értesítés, amely szerint a túra esemény már véget ért.:A túra már véget ért, a regisztráció nem lehetséges!`;
    public readonly msgMaxParticipantsReached = $localize`:Hibaüzenet, amely akkor jelenik meg, ha a túra regisztráció során a maximális résztvevőszámot elérte a túra.:A túrára már nincs üres hely.`;
    public readonly msgRouteCancellationConsequences = $localize`:Értesítés, amely szerint a túra regisztráció törlése következményekkel jár.:Ha szervezőként lemondod a túrát, akkor erről értesítjük a résztvevőket, új résztvevők pedig nem csatlakozhatnak.`;
    public readonly msgRouteCancellatonSuccess = $localize`:Értesítés, amely szerint a túra regisztráció törlése sikeres volt.:A túra lemondása sikeres volt.`;
    public readonly msgCancelledTourBtnNotification = $localize`:Értesítés, amely szerint a lemondott túra gombja nem érhető el.:Lemondott túra esetén a regisztráció vagy lemondás nem elérhető.`;
    public readonly msgEventCreationSuccess = $localize`:Értesítés, amely szerint az esemény létrehozása sikeres volt.:Az esemény létrehozása sikeres volt.`;
    public readonly msgEventCreationFailure = $localize`:Hibaüzenet, amely akkor jelenik meg, ha az esemény létrehozása sikertelen.:Az esemény létrehozása sikertelen.`;
    public readonly msgMaxCharLimitReached = $localize`:Hibaüzenet, amely akkor jelenik meg, ha a felhasználó túllépte a karakterlimitet.:Elérted a maximális karakterlimitet.`;
    public readonly msgPleaseSignIn = $localize`:Értesítés, amely szerint a felhasználónak be kell jelentkeznie: A tartalom megtekintéséhez kérjük, hogy jelentkezz be.`;
    public readonly msgLogoutSuccess = $localize`:Értesítés, amely szerint a kijelentkezés sikeres volt.:Sikeres kijelentkezés.`;
    /** UI messages end here. */

    /** UI contents for the landing page start here. */
    public readonly txtLandingWeBelieve = $localize`:Általános üzenet a landing pagehez.:Hisszük, hogy együtt jobb a gurulás.`;
    public readonly txtLandingCreateInvite = $localize`:Általános üzenet a landing pagehez.:Készíts útvonalakat és hívj meg másokat.`;
    public readonly txtLandingSimple = $localize`:Általános üzenet a landing pagehez.:Ilyen egyszerű.`;

    public readonly txtLandingFindPartners = $localize`:Általános üzenet a landing pagehez.:Találj társakat a gurulásodhoz, legyen szó országútról vagy homokbányáról.`;
    public readonly txtLandingWelcome = $localize`:Általános üzenet a landing pagehez.:Nem számít, hogy az aszfalton vagy a legvadabb terepen vagy otthon.`;

    public readonly txtLandingRestaurants = $localize`:Általános üzenet a landing pagehez.:Vendéglátóhelyek, történelmi helyszínek, lélegzetelállító panorámák?`;
    public readonly txtLandingBrowse = $localize`:Általános üzenet a landing pagehez.:Böngéssz a gurulások között adott tulajdonságok alapján, legyen szó egy kiváló ebédről vagy egy csodás kilátóról.`;

    public readonly txtLandingConnectWith = $localize`:Általános üzenet a landing pagehez.:Csatlakozz helyi motorosokhoz, akik ismerik a környék legjobb útvonalait, az igazi rejtett kincseket és a lélegzetelállító kilátókat.`;
    public readonly txtLandingVibrantCommunity = $localize`:Általános üzenet a landing pagehez.:Valami igazán különleges van a motoros barátságokban. Akár tapasztalt motoros vagy, akár csak az első kétkerekű utadat kezded, a Roadspace kapu egy élénk közösséghez.`;

    public readonly txtLandingIntuitive = $localize`:Általános üzenet a landing pagehez.:Egyszerű & könnyen kezelhető`;
    public readonly txtLandingIntuitiveBody = $localize`:Általános üzenet a landing pagehez.:A felhasználóbarát felület biztosítja a zökkenőmentes élményt.`;
    public readonly txtLandingCustomizedMatching = $localize`:Általános üzenet a landing pagehez.:Egyedi találatok`;
    public readonly txtLandingCustomizedMatchingBody = $localize`:Általános üzenet a landing pagehez.:Állítsd be preferenciáidat, kedvenc útvonalaidat és időbeosztásod: mi összepárosítunk olyan motorosokkal, akik illeszkednek a profilodhoz.`;

    public readonly txtLandingStatisticsProudOf = $localize`:Általános üzenet a landing pagehez.:Számok, amikre büszkék vagyunk`;

    public readonly txtLandingEventOrg = $localize`:Általános üzenet a landing pagehez.:Eseményszervezés`;
    public readonly txtLandingEventOrgBody = $localize`:Általános üzenet a landing pagehez.:Szeretnél csoportos motoros túrát vagy motoros eseményt szervezni? Az alkalmazásunk lehetővé teszi események létrehozását és kezelését, ahol meghívhatod a motorosokat, hogy csatlakozzanak hozzád izgalmas kalandokhoz.`;

    public readonly txtLandingRidingNetwork = $localize`:Általános üzenet a landing pagehez.:Építs közösséget`;
    public readonly txtLandingRidingNetworkBody = $localize`:Általános üzenet a landing pagehez.:Csatlakozz motorosokhoz világszerte. Szerezz új barátokat, bárhová is vezessen az út.`;

    public readonly txtLandingRiderReview = $localize`:Általános üzenet a landing pagehez.:Értékelések`;
    public readonly txtLandingRiderReviewBody = $localize`:Általános üzenet a landing pagehez.:Minden gurulás után értékeld és véleményezd motoros társaidat. Ez a funkció elősegíti a tisztelettudó és élvezetes közösséget, biztosítva, hogy minden út olyan kellemes legyen, amennyire csak lehetséges.`;

    public readonly txtLandingCookieMessage = $localize`:A süti banner fő szövege.:Az oldal sütiket használ a megfelelő működés, valamint a látogatottság nyomon követése érdekében.`;
    public readonly txtLandingCookieDismiss = $localize`:A süti banner elfogadására vonatkozó gomb.:Értettem`;
    public readonly txtLandingCookieDeny = $localize`:A süti banner elutasítására vonatkozó gomb.:Nem fogadom el`;
    public readonly txtLandingCookieLearnMorePolicy = $localize`:A süti banneren a teljes szabályozás oldalára vezető link szövege.:Süti tájékoztató`;

    public readonly txtGoalAdventure = $localize`:Az alkalmazás egyik célját összefoglaló szöveg.:Kaland`;
    public readonly txtGoalCulture = $localize`:Az alkalmazás egyik célját összefoglaló szöveg.:Kultúra`;
    public readonly txtGoalPanorama = $localize`:Az alkalmazás egyik célját összefoglaló szöveg.:Panoráma`;
    /** UI contents for the landing page end here. */

    constructor() {}

    /**
     * TODO: Find a better way of doing this...
     * Used for enum values coming from the backend.
     * @param value The route filter enum value.
     * @returns The translated label for the route filter enum as a string.
     */
    public getRidingStyleLabel(value: FilterValue): string {
        switch (value) {
            case 0:
                return this.allFilterValues;
            case 1:
                return this.sportRidingStyle;
            case 2:
                return this.touringRidingStyle;
            case 3:
                return this.chopperRidingStyle;
            case 4:
                return this.cruiserRidingStyle;
            case 5:
                return this.cafeRacerRidingStyle;
            case 6:
                return this.enduroRidingStyle;
            case 7:
                return this.bobberRidingStyle;
            default:
                return 'Unset';
        }
    }

    public getParticipantsNumberLabel(value: FilterValue): string {
        switch (value) {
            case 0:
                return this.allFilterValues;
            case 1:
                return this.dualParticipantsNumber;
            case 2:
                return this.smallParticipantsNumber;
            case 3:
                return this.midParticipantsNumber;
            case 4:
                return this.largeParticipantsNumber;
            case 5:
                return this.openParticipantsNumber;
            default:
                return 'Unset';
        }
    }

    public getDistanceLabel(value: FilterValue): string {
        switch (value) {
            case 0:
                return this.allFilterValues;
            case 1:
                return this.shortDistance;
            case 2:
                return this.midDistance;
            case 3:
                return this.longDistance;
            default:
                return 'Unset';
        }
    }

    public getTerrainLabel(value: FilterValue): string {
        switch (value) {
            case 0:
                return this.allFilterValues;
            case 1:
                return this.onRoadTerrain;
            case 2:
                return this.offRoadTerrain;
            case 3:
                return this.mixedTerrain;
            default:
                return 'Unset';
        }
    }

    public getSceneryLabel(value: FilterValue): string {
        switch (value) {
            case 0:
                return this.allFilterValues;
            case 2:
                return this.mountainousScenery;
            case 3:
                return this.forestedScenery;
            case 4:
                return this.urbanScenery;
            case 5:
                return this.countrySideScenery;
            default:
                return 'Unset';
        }
    }

    public getPoisLabel(value: FilterValue): string {
        switch (value) {
            case 0:
                return this.allFilterValues;
            case 1:
                return this.historicPois;
            case 2:
                return this.scenicOverviewPois;
            case 3:
                return this.cafesRestaurantsPois;
            default:
                return 'Unset';
        }
    }

    public getEventLabel(value: FilterValue): string {
        switch (value) {
            case 0:
                return this.allFilterValues;
            case 1:
                return this.groupEvent;
            case 2:
                return this.soloEvent;
            case 3:
                return this.rallyEvent;
            case 4:
                return this.charityEvent;
            case 5:
                return this.festivalEvent;
            default:
                return 'Unset';
        }
    }

    public getDurationLabel(value: FilterValue): string {
        switch (value) {
            case 0:
                return this.allFilterValues;
            case 1:
                return this.halfDayDuration;
            case 2:
                return this.fullDayDuration;
            case 3:
                return this.multiDayDuration;
            default:
                return 'Unset';
        }
    }

    public getDifficultyFactorsLabel(value: FilterValue): string {
        switch (value) {
            case 0:
                return this.allFilterValues;
            case 1:
                return this.elevationDifficultyFactor;
            case 2:
                return this.turnsDifficultyFactor;
            case 3:
                return this.trafficDifficultyFactor;
            default:
                return 'Unset';
        }
    }

    public getCommunityRecommendationLabel(value: FilterValue): string {
        switch (value) {
            case 0:
                return this.allFilterValues;
            case 1:
                return this.popularCommunityRecommendation;
            case 2:
                return this.hiddenGemsCommunityRecommendation;
            default:
                return 'Unset';
        }
    }

    public getServicesLabel(value: FilterValue): string {
        switch (value) {
            case 0:
                return this.allFilterValues;
            case 1:
                return this.gasStationService;
            case 2:
                return this.restStopService;
            case 3:
                return this.accommodationService;
            default:
                return 'Unset';
        }
    }
}
